import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import { Form, Formik } from "formik";
import moment from "moment-timezone";
import { EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import { useOutletContext } from "react-router-dom";
import * as yup from "yup";
import useBreakpoint from "../../../breakpointHook";
import { TimezoneSelectForm } from "./TimezoneSelectForm";
import { LocationForm } from "./LocationForm";
import { DescriptionForm } from "./DescriptionForm";
import { DateTimeForm } from "./DateTimeForm";
import { NameForm } from "./NameForm";
import { BottomButtons } from "./BottomButtons";
import { FormPrompt } from "../../FormPrompt/FormPrompt";
import { v4 as uuidv4 } from "uuid";
import {
  updateEvent,
  createEvent,
} from "../../../redux/features/adminEventsSlice";
import {
  getHours,
  getMilliseconds,
  getMinutes,
  getTime,
  setHours,
  setMinutes,
  setSeconds,
  startOfDay,
} from "date-fns";
import SquareLocationPicker from "../../SquareLocationPicker";
import RoomPicker from "../../RoomPicker/RoomPicker";
import {
  fetchRooms,
  fetchShows,
  showsAndRoomsSliceSelector,
} from "../../../redux/features/showsAndRoomsSlice";
import ShowPicker from "../../ShowPicker/ShowPicker";
import { getUtcOffsetFromTimezoneName } from "../../../utils";
import { PerformersForm } from "./PerformersForm";
import { removePerformerFromOrganization } from "../../../redux/features/organizationsSlice";

const { zonedTimeToUtc, utcToZonedTime, format } = require("date-fns-tz");

const editorStateFromHtml = (html) => {
  const contentBlock = htmlToDraft(html);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }
};

const buildFormik = (event) => {
  const {
    name,
    performers,
    description,
    address,
    timezone,
    startsAt,
    endsAt,
    squareLocation,
    showId,
    roomId,
  } = event;
  const {
    placeName,
    addressLine1,
    addressLine2,
    city,
    state,
    zipcode,
    addressTimezone,
  } = address || {};
  const validationSchema = yup.object({
    name: yup.string().trim().required("Name is required"),
    squareLocation: yup.string().trim().required("Square Location is required"),
    placeName: yup.string().trim().required("Location Name is required"),
    addressLine1: yup.string().trim().required("Address Line 1 is required"),
    addressLine2: yup.string().trim(),
    city: yup.string().trim().required("City is required"),
    state: yup.string().trim().required("State is required"),
    zipcode: yup
      .mixed()
      .test("required", "ZIP code is required", (value) => {
        return !!value;
      })
      .test("valid", "ZIP code must be valid", (value) => {
        return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
      }),
    startsAtDate: yup
      .date("Invalid Date")
      .typeError("Invalid Date")
      .required("Start Date is required"),
    startsAtTime: yup
      .date("Invalid Time")
      .typeError("Invalid Time")
      .required("Start Time is required"),
    endsAtDate: yup
      .date("Invalid Date")
      .typeError("Invalid Date")
      .required("End Date is required"),
    endsAtTime: yup
      .date("Invalid Time")
      .typeError("Invalid Time")
      .required("End Time is required"),
    timezone: yup.string().required("Time Zone is required"),
  });

  const startsAtDate = startsAt ? moment.tz(startsAt.toDate(), timezone) : null;
  const startsAtTime = startsAt ? moment.tz(startsAt.toDate(), timezone) : null;
  const endsAtDate = endsAt ? moment.tz(endsAt.toDate(), timezone) : null;
  const endsAtTime = endsAt ? moment.tz(endsAt.toDate(), timezone) : null;

  const sanitizedPerformers = (performers || []).map((performer) => {
    const { name, url, key } = performer;

    return {
      name: name || "",
      url: url || "",
      key: key || uuidv4(),
      isEditing: false,
      isNew: false,
    };
  });
  const initialValues = {
    name,
    performers: sanitizedPerformers,
    description,
    placeName,
    addressLine1,
    addressLine2,
    city,
    state,
    zipcode,
    addressTimezone,
    startsAtDate,
    startsAtTime,
    endsAtDate,
    endsAtTime,
    timezone,
    squareLocation,
    showId,
    roomId,
  };

  return { validationSchema, initialValues };
};

const rezoneMomentDateTime = (mDate, mTime, timezone) => {
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm";
  const formattedDate = mDate.format(dateFormat);
  const formattedTime = mTime.format(timeFormat);
  return moment.tz(`${formattedDate} ${formattedTime}`, timezone);
};

const buildValuesPayload = (values) => {
  const addressFields = [
    "placeName",
    "addressLine1",
    "addressLine2",
    "city",
    "state",
    "zipcode",
    "addressTimezone",
  ];

  let newValues = { ...values };

  const { startsAtDate, startsAtTime, endsAtDate, endsAtTime, timezone } =
    newValues;
  const timeValues = {
    startsAt: Timestamp.fromDate(
      rezoneMomentDateTime(startsAtDate, startsAtTime, timezone).toDate(),
    ),
    endsAt: Timestamp.fromDate(
      rezoneMomentDateTime(endsAtDate, endsAtTime, timezone).toDate(),
    ),
  };
  delete newValues.startsAtDate;
  delete newValues.startsAtTime;
  delete newValues.endsAtDate;
  delete newValues.endsAtTime;
  newValues = { ...newValues, ...timeValues };

  const address = addressFields.reduce((acc, addressField) => {
    const value = newValues[addressField];
    delete newValues[addressField];
    return {
      ...acc,
      [addressField]: value || "",
    };
  }, {});

  const sanitizedPerformers = (newValues.performers || []).map((performer) => {
    const { name, url, key } = performer;
    return {
      name,
      url,
      key,
    };
  });

  const valuesPayload = {
    ...newValues,
    address,
    performers: sanitizedPerformers,
  };

  return Object.keys(valuesPayload).reduce((acc, key) => {
    const _acc = acc;
    if (valuesPayload[key] !== undefined) _acc[key] = valuesPayload[key];
    return _acc;
  }, {});
};

const EventBasicInfo = ({}) => {
  const { event, creatingNew, organization } = useOutletContext();
  const showsAndRoomsState = useSelector((state) =>
    showsAndRoomsSliceSelector(state),
  );
  const {
    id: organizationId,
    addressHistory,
    squareLocations,
    performers: organizationPerformers,
  } = organization;
  const {
    rooms,
    shows,
    showsFetched,
    roomsFetched,
    showsLoading,
    roomsLoading,
  } = showsAndRoomsState;
  const { id: eventId, loading } = event;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (organization && !roomsLoading && !roomsFetched) {
      dispatch(fetchRooms({ organizationId }));
    }
  }, [roomsLoading, roomsFetched, organization]);

  useEffect(() => {
    if (organization && !showsLoading && !showsFetched) {
      dispatch(fetchShows({ organizationId }));
    }
  }, [showsLoading, showsFetched, organization]);

  const handleSaveChanges = async (values) => {
    await dispatch(updateEvent({ eventId, values }));
  };
  const handleCreateNew = async (values) => {
    await dispatch(createEvent({ values }));
  };
  const handleCancelNew = () => {
    navigate("/events");
  };

  const { options: timezoneOptions, parseTimezone } = useTimezoneSelect({
    labelStyle: "original",
    allTimezones,
  });

  const descriptionHtml = event?.description || "";
  const [descriptionState, setDescriptionState] = React.useState(
    editorStateFromHtml(descriptionHtml),
  );

  if (loading) {
    return;
  }

  const { validationSchema, initialValues } = buildFormik(event);

  const roomsList = Object.keys(rooms).map((roomId) => rooms[roomId]);
  const showsList = Object.keys(shows).map((showId) => shows[showId]);
  return (
    <Box
      sx={{
        paddingTop: "30px",
        paddingBottom: "90px",
        position: "relative",
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, submitProps) => {
          if (creatingNew) {
            handleCreateNew(buildValuesPayload(values));
          } else {
            await handleSaveChanges(buildValuesPayload(values));
            submitProps.resetForm({ values });
          }
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          setValues,
          setFieldValue,
          handleReset,
          setFieldTouched,
        }) => (
          <Form noValidate autoComplete="off">
            <FormPrompt hasUnsavedChanges={dirty} />
            <NameForm
              formikProps={{
                values,
                setValues,
                handleChange,
                handleBlur,
                touched,
                errors,
              }}
            />

            <ShowPicker
              formikProps={{
                values,
                handleChange,
                setFieldValue,
                setValues,
                handleBlur,
                touched,
                errors,
              }}
              shows={showsList}
            />
            <RoomPicker
              formikProps={{
                values,
                handleChange,
                setFieldValue,
                setValues,
                handleBlur,
                touched,
                errors,
              }}
              rooms={roomsList}
              handleChange={(e) => {
                const roomId = e.target.value;
                const { name, squareLocation, address } = rooms[roomId];
                const {
                  addressLine1,
                  addressLine2,
                  city,
                  state,
                  zipcode,
                  addressTimezone,
                } = address;

                if (Boolean(squareLocation)) {
                  setFieldValue("squareLocation", squareLocation);
                }
                setFieldValue("placeName", name);
                setFieldValue("addressLine1", addressLine1);
                setFieldValue("addressLine2", addressLine2);
                setFieldValue("city", city);
                setFieldValue("state", state);
                setFieldValue("zipcode", zipcode);
                setFieldValue("addressTimezone", addressTimezone);
                if (Boolean(addressTimezone)) {
                  let correctTimezone;
                  if (
                    timezoneOptions
                      .map(({ value }) => value)
                      .includes(addressTimezone)
                  ) {
                    correctTimezone = addressTimezone;
                  } else {
                    try {
                      const utcOffset =
                        getUtcOffsetFromTimezoneName(addressTimezone);
                      const timezone = timezoneOptions.find(
                        ({ offset }) => utcOffset / 60 === offset,
                      );
                      if (Boolean(timezone)) {
                        correctTimezone = timezone.value;
                      }
                    } catch (e) {
                      console.log("invalid timezone", addressTimezone);
                    }
                  }
                  if (correctTimezone) {
                    setFieldValue("timezone", correctTimezone);
                  }
                }
              }}
            />

            <SquareLocationPicker
              required
              formikProps={{
                values,
                handleChange,
                setFieldValue,
                setValues,
                handleBlur,
                touched,
                errors,
              }}
              squareLocations={squareLocations}
            />

            <LocationForm
              formikProps={{
                values,
                handleChange,
                setFieldValue,
                setValues,
                handleBlur,
                touched,
                errors,
              }}
              addressHistory={addressHistory}
              nameOverride="Event Location"
              handleTimezoneChanged={(timezone) => {
                setFieldValue("timezone", timezone);
              }}
            />
            <DateTimeForm
              formikProps={{
                values,
                handleChange,
                setFieldValue,
                setFieldTouched,
                touched,
                errors,
              }}
            />
            <TimezoneSelectForm
              formikProps={{
                values,
                handleChange,
                handleBlur,
                touched,
                errors,
              }}
              options={timezoneOptions}
              handleTimezoneChanged={(timezone) => {
                setFieldValue("addressTimezone", timezone);
              }}
            />
            <PerformersForm
              organizationPerformers={organizationPerformers}
              formikProps={{
                values,
                setFieldValue,
              }}
              handleDeleterPerformerFromOrganization={({ key }) => {
                dispatch(
                  removePerformerFromOrganization({ organizationId, key }),
                );
              }}
            />
            <DescriptionForm
              formikProps={{
                values,
                handleChange,
              }}
              descriptionState={descriptionState}
              handleStateChange={setDescriptionState}
            />
            <BottomButtons
              show={creatingNew || dirty}
              handleDiscard={() => {
                if (creatingNew) {
                  handleCancelNew();
                } else {
                  setDescriptionState(editorStateFromHtml(descriptionHtml));
                  handleReset();
                }
              }}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EventBasicInfo;
