import React from "react";
import { Paper, IconButton, Box, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

export const PerformerView = ({
  performer,
  handleDelete,
  handleEdit,
  dhProps,
}) => {
  const { name, url, key } = performer;

  return (
    <Paper
      width={"100%"}
      sx={{
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "20px",
        paddingBottom: "20px",
      }}
      elevation={4}
    >
      <Box sx={{ textDecoration: "none" }}>
        <Box
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={"20px"}
        >
          <Box
            {...dhProps}
            paddingTop={"5px"}
            paddingBottom={"0px"}
            paddingLeft={"5px"}
            paddingRight={"5px"}
          >
            <UnfoldMoreIcon color="primary" />
          </Box>
          <Box flexGrow={"1"}>
            <Typography variant="" color={"text.primary"}>
              {name}
            </Typography>
            <Typography color={"primary.lightText"}>{url}</Typography>
          </Box>
          <IconButton
            sx={{ marginLeft: "auto" }}
            onClick={(e) => {
              e.preventDefault();
              handleEdit();
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            sx={{ marginLeft: "auto" }}
            onClick={(e) => {
              e.preventDefault();
              handleDelete();
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
};
