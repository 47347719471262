import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { FieldArray, getIn } from "formik";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { PerformerView } from "../EventPerformers/PerformerView";
import { AddPerformerModal } from "./AddPerformerModal";

export const lockAxisStyle = (style) => {
  if (style?.transform) {
    const axisLockY = `translate(0px, ${style.transform.split(",").pop()}`;
    return {
      ...style,
      transform: axisLockY,
    };
  }
  return style;
};

export const getItemStyle = (draggableStyle) => ({
  userSelect: "none",
  margin: `0 0 20px 0`,
  ...draggableStyle,
});

export const PerformersForm = ({
  formikProps,
  organizationPerformers,
  handleDeleterPerformerFromOrganization,
}) => {
  const [editingPerformer, setEditingPerformer] = useState(null);
  const { values, setFieldValue } = formikProps;

  const performers = values.performers;

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const newList = [...performers];
    const [removed] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, removed);
    setFieldValue("performers", newList);
  };
  return (
    <Box>
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={{ xs: "left", md: "center" }}
        gap={"5px"}
        sx={{ marginTop: "20px" }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Typography flexGrow={1}>Performers</Typography>
      </Box>
      <FieldArray name={"performers"}>
        {({ push, remove, replace }) => (
          <Box>
            <AddPerformerModal
              {...{
                editingPerformer,
                open: Boolean(editingPerformer),
                handleClose: () => {
                  setEditingPerformer(null);
                },
                organizationPerformers,
                eventPerformers: performers,
                handleDeletePerformer: handleDeleterPerformerFromOrganization,
                handleAddPerformer: (performer) => {
                  push(performer);
                  setEditingPerformer(null);
                },
                handleUpdatePerformer: (performer) => {
                  const index = performers.findIndex(
                    ({ key }) => key == performer.key,
                  );
                  replace(index, performer);
                  setEditingPerformer(null);
                },
              }}
            />
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ width: "100%", marginTop: "20px" }}
                  >
                    {(performers || []).map((performer, index) => {
                      return (
                        <Draggable
                          key={`performer-${index}`}
                          draggableId={`performer-${index}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={lockAxisStyle(
                                getItemStyle(provided.draggableProps.style),
                              )}
                            >
                              <PerformerView
                                handleDelete={() => {
                                  remove(index);
                                }}
                                handleEdit={async () => {
                                  await setEditingPerformer(performer);
                                }}
                                dhProps={provided.dragHandleProps}
                                {...{
                                  performer,
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        )}
      </FieldArray>

      <Button
        variant="outlined"
        sx={{ width: "300px", marginTop: "10px", textAlign: "center" }}
        onClick={() => {
          setEditingPerformer({ initial: true });
        }}
      >
        Add Performer
      </Button>
    </Box>
  );
};
