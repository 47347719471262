import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
// import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Form, Formik, useFormikContext } from "formik";
import * as yup from "yup";
import { CatchingPokemonSharp } from "@mui/icons-material";
import ConfirmationDialog from "../../ConfirmationDialog";

const defaultInitialValues = {
  name: "",
  url: "",
  key: uuidv4(),
  isNew: true,
  isEditing: false,
  initial: true,
};

export const AddPerformerModal = ({
  open,
  eventPerformers,
  editingPerformer,
  handleClose,
  organizationPerformers,
  handleDeletePerformer,
  handleAddPerformer,
  handleUpdatePerformer,
}) => {
  const filter = createFilterOptions();
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [showConfirmDeleteFromOrg, setShowConfirmDeleteFromOrg] =
    useState(false);
  useEffect(() => {
    if (editingPerformer) {
      const { name, url, key, initial } = editingPerformer;
      if (initial) {
        setInitialValues(defaultInitialValues);
        return;
      }
      const foundInOrg = organizationPerformers.find(
        ({ key: existingKey }) => existingKey === key,
      );
      const foundInEvent = eventPerformers.find(
        ({ key: existingKey }) => existingKey === key,
      );
      setInitialValues({
        name,
        url,
        key,
        isInEvent: Boolean(foundInEvent),
        isInOrg: Boolean(foundInOrg),
        isNew: false,
        isEditing: Boolean(foundInOrg) || Boolean(foundInEvent),
        initial: false,
      });
    }
  }, [editingPerformer]);

  const eventPerformersKeys = eventPerformers.map(({ key }) => key);
  let orgPerformersOptions = (organizationPerformers || [])
    .sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    })
    .filter(({ key }) => !eventPerformersKeys.includes(key));

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", sm: "100%", md: 600 },
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          maxHeight: "90vh",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}
        setselected
      >
        <ConfirmationDialog
          open={Boolean(showConfirmDeleteFromOrg)}
          title={`Delete ${showConfirmDeleteFromOrg?.name} from organization?`}
          message={
            "Are you sure you want to delete this performer form your organization? Events with this performer assigned will not be affected"
          }
          buttonDefsOverride={[
            {
              text: "Cancel",
              onClick: () => {
                setShowConfirmDeleteFromOrg(null);
              },
            },
            {
              text: "Delete",
              onClick: async () => {
                await handleDeletePerformer(showConfirmDeleteFromOrg);
                setShowConfirmDeleteFromOrg(null);
              },
            },
          ]}
        />

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={yup.object({
            name: yup.string().trim().required("Name is required"),
            url: yup.string().trim().url("Must be a valid url"),
          })}
          onSubmit={(values, submitProps) => {
            const { name, url, key, isInEvent } = values;
            const sanitizedValues = { name, url, key };
            if (isInEvent) {
              handleUpdatePerformer(sanitizedValues);
            } else {
              handleAddPerformer(sanitizedValues);
            }
          }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            isValid,
            dirty,
            setValues,
            setFieldValue,
            handleReset,
            submitForm,
          }) => {
            let infoText = "";
            let addButtonText = "Add";
            let titleText = "Add Performer";
            if (values.isInEvent && values.isInOrg) {
              titleText = "Update Performer";
              addButtonText = "Update";
              infoText =
                "Performer will be updated in your Event and Organization";
            } else if (values.isInEvent && !values.isInOrg) {
              titleText = "Update Performer";
              addButtonText = "Create and Update";
              infoText =
                "Performer will be created in your Organization and updated in this Event";
            } else if (values.isNew) {
              addButtonText = "Create and Add";
              infoText =
                "Performer will be created in your Organization and added to this Event";
            } else if (values.isEditing) {
              infoText =
                "Performer will be added to this Event and updated in your Organization";
              addButtonText = "Add and Update";
            }
            return (
              <Form noValidate autoComplete="off">
                <Typography variant="h5" marginBottom={"30px"}>
                  {titleText}
                </Typography>
                <Box display={"flex"} flexDirection={"column"} gap={"0px"}>
                  <Autocomplete
                    selectOnFocus
                    handleHomeEndKeys
                    freeSolo
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return option?.key == value?.key;
                    }}
                    onInputChange={(event, newValue) => {
                      const newOverrideValues =
                        values.isEditing || values.isNew
                          ? {}
                          : { isNew: true, key: uuidv4() };
                      if (newValue !== values.name) {
                        setValues({
                          ...values,
                          name: newValue,
                          ...newOverrideValues,
                        });
                      }
                    }}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setValues({
                          ...values,
                          initial: false,
                          name: newValue,
                          url: "",
                        });
                      } else if (newValue && newValue.inputValue) {
                        setValues({
                          ...initialValues,
                          name: newValue.inputValue,
                          isNew: true,
                          initial: false,
                        });
                      } else {
                        setValues(newValue || initialValues);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      const isExisting = options.some(
                        (option) => inputValue === option.key,
                      );
                      if (
                        inputValue !== "" &&
                        !isExisting &&
                        !Boolean(values.isEditing)
                      ) {
                        filtered.splice(0, 0, {
                          inputValue,
                          name: `Add New: "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                      console.log("blurred", e);
                      if (values.initial && values.name.length > 0) {
                        setValues({ ...values, initial: false });
                      }
                      //   setValues({
                      //     ...initialValues,
                      //     name: newValue.inputValue,
                      //     isNew: true,
                      //     initial: false,
                      //   });
                    }}
                    value={values}
                    options={
                      Boolean(values.isEditing) ? [] : orgPerformersOptions
                    }
                    sx={{ width: "100%" }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Full Name"
                          name={"name"}
                          error={Boolean(touched.name && errors.name)}
                          helperText={
                            touched.name && errors.name ? errors.name : " "
                          }
                        />
                      );
                    }}
                    renderOption={(props, option) => {
                      const { key, ...optionProps } = props;
                      return (
                        <Box
                          key={option.key}
                          component="li"
                          {...optionProps}
                          display={"flex"}
                          gap={"5px"}
                          width={"100%"}
                        >
                          <Box flexGrow={"1"}>
                            <Typography
                              color={option.inputValue ? "primary" : ""}
                            >
                              {option.name}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color={"primary.lightText"}
                              sx={{ paddingRight: "5px" }}
                            >
                              {option.url}
                            </Typography>
                          </Box>
                          {!option.inputValue && (
                            <>
                              <IconButton
                                sx={{ marginLeft: "auto" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setValues({
                                    ...option,
                                    initial: false,
                                    isEditing: true,
                                  });
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                sx={{ marginLeft: "auto" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setShowConfirmDeleteFromOrg(option);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </>
                          )}
                        </Box>
                      );
                    }}
                  />
                  <TextField
                    disabled={
                      values.initial ||
                      (!Boolean(values.isEditing) && !Boolean(values.isNew))
                    }
                    id={"url"}
                    name={"url"}
                    label="URL"
                    value={values.url || ""}
                    onBlur={handleBlur}
                    error={Boolean(touched.url && errors.url)}
                    helperText={touched.url && errors.url ? errors.url : " "}
                    onChange={handleChange}
                    sx={{ marginTop: "20px" }}
                    InputProps={{
                      endAdornment:
                        //!Boolean(values.initial) &&
                        !Boolean(values.isNew) &&
                          !Boolean(values.isEditing) && (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  setValues({
                                    ...values,
                                    initial: false,
                                    isEditing: true,
                                  });
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    gap: "20px",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{ flexBasis: "100%" }}
                    onClick={async () => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={Boolean(values.initial)}
                    variant="contained"
                    type="submit"
                    sx={{ flexBasis: "100%" }}
                    endIcon={
                      Boolean(infoText) && (
                        <Tooltip title={infoText}>
                          <InfoIcon />
                        </Tooltip>
                      )
                    }
                  >
                    {addButtonText}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Modal>
  );
};
